import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/AdminDashboard.css'; 

const AdminDashboard = () => {
    const navigate = useNavigate();
    const [motorcycles, setMotorcycles] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        brand: '',
        model: '',
        year: '',
        price: '',
        description: '',
        colours: '',
        image: null,
        motor_type: '',
        rated_power: '',
        controller: '',
        wheel: '',
        max_speed: '',
        max_torque: '',
        tire_size: '',
        shock_absorption: '',
        front_brake: '',
        rear_brake: '',
        overall_length: '',
        overall_width: '',
        overall_height: '',
        wheelbase: '',
        ground_clearance: '',
        battery_type: '',
        battery_capacity: '',
        battery_voltage: '',
        meter: ''
    });
    const [message, setMessage] = useState(''); 
    const [showForm, setShowForm] = useState(true); 
    const [showMoreInfo, setShowMoreInfo] = useState(false); 
    const [editMode, setEditMode] = useState(false); 
    const [editId, setEditId] = useState(null); 

    useEffect(() => {
        document.title = 'Admin Dashboard | P&U Cycle';
        if (!localStorage.getItem('token')) {
            navigate('/admin');
        }

        const fetchMotorcycles = async () => {
            const res = await axios.get('https://petalingutamamotor.com/api/motorcycles');
            setMotorcycles(res.data);
        };
        fetchMotorcycles();
    }, [navigate]);

    const handleChange = (e) => {
        if (e.target.name === 'image') {
            setFormData({ ...formData, image: e.target.files[0] });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
    
        try {
            if (editMode && editId) {
                // Update motorcycle (PUT request)
                const res = await axios.put(`https://petalingutamamotor.com/api/motorcycles/${editId}`, data, {
                    headers: {
                        'x-auth-token': localStorage.getItem('token')
                    }
                });
                console.log('MOTORCYCLE UPDATED SUCCESSFULLY:', res.data);
                setMessage('Successfully updated!');
            } else {
                // Add new motorcycle (POST request)
                const res = await axios.post('https://petalingutamamotor.com/api/motorcycles', data, {
                    headers: {
                        'x-auth-token': localStorage.getItem('token')
                    }
                });
                console.log('MOTORCYCLE ADDED SUCCESSFULLY:', res.data);
                setMessage('Successfully submitted! Do you want to do another listing?');
            }
    
            setShowForm(false);
            setEditMode(false); 
            setEditId(null); 
            const fetchMotorcycles = async () => {
                const res = await axios.get('https://petalingutamamotor.com/api/motorcycles');
                setMotorcycles(res.data);
            };
            fetchMotorcycles();
        } catch (err) {
            console.error('Error:', err);
            setMessage('Submission failed. Please try again.');
        }
    };    

    const handleEdit = (motorcycle) => {
        setFormData({
            name: motorcycle.name,
            brand: motorcycle.brand,
            model: motorcycle.model,
            year: motorcycle.year,
            price: motorcycle.price,
            description: motorcycle.description,
            colours: motorcycle.colours,
            image: null, 
            motor_type: motorcycle.motor_type,
            rated_power: motorcycle.rated_power,
            controller: motorcycle.controller,
            wheel: motorcycle.wheel,
            max_speed: motorcycle.max_speed,
            max_torque: motorcycle.max_torque,
            tire_size: motorcycle.tire_size,
            shock_absorption: motorcycle.shock_absorption,
            front_brake: motorcycle.front_brake,
            rear_brake: motorcycle.rear_brake,
            overall_length: motorcycle.overall_length,
            overall_width: motorcycle.overall_width,
            overall_height: motorcycle.overall_height,
            wheelbase: motorcycle.wheelbase,
            ground_clearance: motorcycle.ground_clearance,
            battery_type: motorcycle.battery_type,
            battery_capacity: motorcycle.battery_capacity,
            battery_voltage: motorcycle.battery_voltage,
            meter: motorcycle.meter
        });
        setEditId(motorcycle.id);
        setEditMode(true);
        setShowForm(true);
        console.log('MOTORCYCLE EDITED SUCCESSFULLY');
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this motorcycle?')) {
            try {
                await axios.delete(`https://petalingutamamotor.com/api/motorcycles/${id}`, {
                    headers: {
                        'x-auth-token': localStorage.getItem('token')
                    }
                });
                setMessage('Motorcycle deleted successfully.');
                const fetchMotorcycles = async () => {
                    const res = await axios.get('https://petalingutamamotor.com/api/motorcycles');
                    setMotorcycles(res.data);
                };
                fetchMotorcycles();
            } catch (err) {
                console.error('Error:', err);
                setMessage('Failed to delete motorcycle. Please try again.');
            }
        }
    };
    

    const handleNewListing = () => {
        setFormData({
            name: '',
            brand: '',
            model: '',
            year: '',
            price: '',
            description: '',
            colours: '',
            image: null,
            motor_type: '',
            rated_power: '',
            controller: '',
            wheel: '',
            max_speed: '',
            max_torque: '',
            tire_size: '',
            shock_absorption: '',
            front_brake: '',
            rear_brake: '',
            overall_length: '',
            overall_width: '',
            overall_height: '',
            wheelbase: '',
            ground_clearance: '',
            battery_type: '',
            battery_capacity: '',
            battery_voltage: '',
            meter: ''
        });
        setEditMode(false);
        setEditId(null);
        setMessage('');
        setShowForm(true);
    };

    const toggleMoreInfo = () => {
        setShowMoreInfo(!showMoreInfo);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/admin');
    };

    return (
        <div className="admin-dashboard">
            <button onClick={handleLogout}>Logout</button>
            <h1>Admin Dashboard</h1>
            {message && (
                <div className="message">
                    <p>{message}</p>
                    {!showForm && (
                        <button onClick={handleNewListing}>
                            {editMode ? 'Edit Another Listing' : 'Create Another Listing'}
                        </button>
                    )}
                </div>
            )}
            {showForm && (
                <form onSubmit={handleSubmit} className="admin-form">
                    <input name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
                    <input name="brand" placeholder="Brand" value={formData.brand} onChange={handleChange} required />
                    <input name="model" placeholder="Model" value={formData.model} onChange={handleChange} required />
                    <input name="year" placeholder="Year" value={formData.year} onChange={handleChange} required />
                    <input name="price" placeholder="Price" value={formData.price} onChange={handleChange} required />
                    <textarea name="description" placeholder="Description" value={formData.description} onChange={handleChange} required />
                    <input name="colours" placeholder="Colours" value={formData.colours} onChange={handleChange} required />
                    <input type="file" name="image" onChange={handleChange} />
                    
                    <div className="more-info">
                        <button type="button" onClick={toggleMoreInfo}>
                            More Motorcycle Information
                        </button>
                        {showMoreInfo && (
                            <div className="more-info-fields">
                                <input name="motor_type" placeholder="Motor Type" value={formData.motor_type} onChange={handleChange} />
                                <input name="rated_power" placeholder="Rated Power" value={formData.rated_power} onChange={handleChange} />
                                <input name="controller" placeholder="Controller" value={formData.controller} onChange={handleChange} />
                                <input name="wheel" placeholder="Wheel" value={formData.wheel} onChange={handleChange} />
                                <input name="max_speed" placeholder="Max Speed" value={formData.max_speed} onChange={handleChange} />
                                <input name="max_torque" placeholder="Max Torque" value={formData.max_torque} onChange={handleChange} />
                                <input name="tire_size" placeholder="Tire Size" value={formData.tire_size} onChange={handleChange} />
                                <input name="shock_absorption" placeholder="Shock Absorption" value={formData.shock_absorption} onChange={handleChange} />
                                <input name="front_brake" placeholder="Front Brake" value={formData.front_brake} onChange={handleChange} />
                                <input name="rear_brake" placeholder="Rear Brake" value={formData.rear_brake} onChange={handleChange} />
                                <input name="overall_length" placeholder="Overall Length" value={formData.overall_length} onChange={handleChange} />
                                <input name="overall_width" placeholder="Overall Width" value={formData.overall_width} onChange={handleChange} />
                                <input name="overall_height" placeholder="Overall Height" value={formData.overall_height} onChange={handleChange} />
                                <input name="wheelbase" placeholder="Wheelbase" value={formData.wheelbase} onChange={handleChange} />
                                <input name="ground_clearance" placeholder="Ground Clearance" value={formData.ground_clearance} onChange={handleChange} />
                                <input name="battery_type" placeholder="Battery Type" value={formData.battery_type} onChange={handleChange} />
                                <input name="battery_capacity" placeholder="Battery Capacity" value={formData.battery_capacity} onChange={handleChange} />
                                <input name="battery_voltage" placeholder="Battery Voltage" value={formData.battery_voltage} onChange={handleChange} />
                                <input name="meter" placeholder="Meter" value={formData.meter} onChange={handleChange} />
                            </div>
                        )}
                    </div>

                    <button type="submit">
                        {editMode ? 'Update Motorcycle' : 'Add Motorcycle'}
                    </button>
                </form>
            )}
            <h2>Motorcycle Listings</h2>
            <ul className="motorcycle-list">
                {motorcycles.map(motorcycle => (
                    <li key={motorcycle.id} className="motorcycle-item">
                        <h3>{motorcycle.name}</h3>
                        <p>{motorcycle.description}</p>
                        {motorcycle.image_url && (
                            <img src={`https://petalingutamamotor.com${motorcycle.image_url}`} alt={motorcycle.name} width="200" />
                        )}
                        <button onClick={() => handleEdit(motorcycle)}>Edit</button>
                        <button onClick={() => handleDelete(motorcycle.id)}>Delete</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AdminDashboard;
