import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../css/MotorcycleDetail.css';
import Header from './Header';
import Footer from './Footer';

const MotorcycleDetail = () => {
    useEffect(() => {
        document.title = 'Motorcycle Detail | P&U Cycle';
    }, []);

    const { id } = useParams();
    const [motorcycle, setMotorcycle] = useState(null);
    const [showMore, setShowMore] = useState(false);

    useEffect(() => {
        axios.get(`https://petalingutamamotor.com/api/motorcycles/${id}`)
            .then(res => setMotorcycle(res.data))
            .catch(err => console.error(err));
    }, [id]);

    if (!motorcycle) return <div className="error-message">Motorcycle doesn't exist.</div>;

    const whatsappNumber = '60173353463'; // The WhatsApp number you want to send the message to
    const whatsappMessage = `Hello, I'm interested in ${motorcycle.name}`; // Dynamic message

    const whatsappLink = `https://web.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(whatsappMessage)}`;

    return (
        <div className="motorcycle-detail">
            <Header />
            <main className="container">
                <div className="content-row">
                    <div className="image-section">
                        <img src={`https://petalingutamamotor.com${motorcycle.image_url}`} alt={motorcycle.name} className="motorcycle-image" />
                    </div>
                    <div className="details-section">
                        <h1>{motorcycle.name}</h1>
                        <h2>RM {motorcycle.price.toLocaleString()}</h2>
                        <ul className="key-specs">
                            <li><strong>Brand:</strong> {motorcycle.brand}</li>
                            <li><strong>Model:</strong> {motorcycle.model}</li>
                            <li><strong>Year:</strong> {motorcycle.year}</li>
                            <li><strong>Colors:</strong> {motorcycle.colours}</li>
                        </ul>
                        <a href={whatsappLink} className="whatsapp-link" target="_blank" rel="noopener noreferrer">
                            Contact via WhatsApp
                        </a>
                    </div>
                </div>
                <div className="show-more-section">
                    <button className="toggle-btn" onClick={() => setShowMore(!showMore)}>
                        {showMore ? 'Show Less' : 'Show More'}
                    </button>

                    {showMore && (
                        <section className="motorcycle-details">
                            <h3>Detailed Specifications</h3>
                            <ul className="specs-list">
                                <li><strong>Motor Type:</strong> {motorcycle.motor_type}</li>
                                <li><strong>Rated Power:</strong> {motorcycle.rated_power}</li>
                                <li><strong>Controller:</strong> {motorcycle.controller}</li>
                                <li><strong>Wheel:</strong> {motorcycle.wheel}</li>
                                <li><strong>Max Speed:</strong> {motorcycle.max_speed}</li>
                                <li><strong>Max Torque:</strong> {motorcycle.max_torque}</li>
                                <li><strong>Tire Size:</strong> {motorcycle.tire_size}</li>
                                <li><strong>Shock Absorption:</strong> {motorcycle.shock_absorption}</li>
                                <li><strong>Front Brake:</strong> {motorcycle.front_brake}</li>
                                <li><strong>Rear Brake:</strong> {motorcycle.rear_brake}</li>
                                <li><strong>Overall Length:</strong> {motorcycle.overall_length}</li>
                                <li><strong>Overall Width:</strong> {motorcycle.overall_width}</li>
                                <li><strong>Overall Height:</strong> {motorcycle.overall_height}</li>
                                <li><strong>Wheelbase:</strong> {motorcycle.wheelbase}</li>
                                <li><strong>Ground Clearance:</strong> {motorcycle.ground_clearance}</li>
                                <li><strong>Battery Type:</strong> {motorcycle.battery_type}</li>
                                <li><strong>Battery Capacity:</strong> {motorcycle.battery_capacity}</li>
                                <li><strong>Battery Voltage:</strong> {motorcycle.battery_voltage}</li>
                                <li><strong>Meter:</strong> {motorcycle.meter}</li>
                            </ul>
                        </section>
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default MotorcycleDetail;
