// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-dashboard {
    padding: 20px;
}

h1, h2 {
    text-align: center;
    color: #333;
}

.admin-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.admin-form input, .admin-form textarea {
    width: 300px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.admin-form button {
    width: 320px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.admin-form button:hover {
    background-color: #0056b3;
}

.motorcycle-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.motorcycle-item {
    background-color: #f9f9f9;
    margin: 10px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    width: 300px;
}

.motorcycle-item h3 {
    margin-top: 0;
}

.motorcycle-item img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/css/AdminDashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;IACd,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,aAAa;IACb,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".admin-dashboard {\n    padding: 20px;\n}\n\nh1, h2 {\n    text-align: center;\n    color: #333;\n}\n\n.admin-form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 20px;\n}\n\n.admin-form input, .admin-form textarea {\n    width: 300px;\n    padding: 10px;\n    margin: 10px 0;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n}\n\n.admin-form button {\n    width: 320px;\n    padding: 10px;\n    background-color: #007bff;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.admin-form button:hover {\n    background-color: #0056b3;\n}\n\n.motorcycle-list {\n    list-style-type: none;\n    padding: 0;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n}\n\n.motorcycle-item {\n    background-color: #f9f9f9;\n    margin: 10px;\n    padding: 20px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    text-align: center;\n    width: 300px;\n}\n\n.motorcycle-item h3 {\n    margin-top: 0;\n}\n\n.motorcycle-item img {\n    max-width: 100%;\n    height: auto;\n    border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
