import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import '../css/Shop.css';
import Header from './Header';
import Footer from './Footer';

const Shop = () => {
    useEffect(() => {
        document.title = 'Shop | P&U Cycle';
    }, []);

    const [motorcycles, setMotorcycles] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [sortOption, setSortOption] = useState('default'); // State for sort option
    const location = useLocation();

    // Function to get query parameter from URL
    const getQueryParam = (param) => {
        return new URLSearchParams(location.search).get(param);
    };

    useEffect(() => {
        axios.get('https://petalingutamamotor.com/api/motorcycles')
            .then(res => {
                setMotorcycles(res.data);
            })
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        // Check if a brand is specified in the URL
        const brand = getQueryParam('brand');
        if (brand) {
            setSearchQuery(brand);
        }
    }, [location.search]);

    const baseUrl = 'https://petalingutamamotor.com'; 

    // Filter motorcycles based on search query
    const filteredMotorcycles = motorcycles.filter(motorcycle =>
        motorcycle.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        motorcycle.brand.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Sort motorcycles based on sort option
    const sortedMotorcycles = [...filteredMotorcycles].sort((a, b) => {
        if (sortOption === 'price-asc') {
            return a.price - b.price;
        } else if (sortOption === 'price-desc') {
            return b.price - a.price;
        } else if (sortOption === 'newest') {
            return new Date(b.createdAt) - new Date(a.createdAt);
        } else if (sortOption === 'oldest') {
            return new Date(a.createdAt) - new Date(b.createdAt);
        } else {
            return 0;
        }
    });

    return (
        <div>
            <Header />
            <div className="heading-container">
                <h1><i className="fa fa-basket-shopping"></i> Shop</h1>
            </div>

            <div className="search-bar-container">
                <input
                    type="text"
                    placeholder="Search by name or brand..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)} // Update search query on input change
                    className="search-bar"
                />
            </div>

            <div className="sort-container">
                <label htmlFor="sort">Sort by: </label>
                <select
                    id="sort"
                    value={sortOption}
                    onChange={e => setSortOption(e.target.value)} // Update sort option on change
                >
                    <option value="default">Default</option>
                    <option value="price-asc">Price: Low to High</option>
                    <option value="price-desc">Price: High to Low</option>
                    <option value="newest">Newest to Oldest</option>
                    <option value="oldest">Oldest to Newest</option>
                </select>
            </div>

            <div className="motorcycle-list">
                {sortedMotorcycles.map(motorcycle => (
                    <div key={motorcycle.id} className="motorcycle-item">
                        {motorcycle.image_url && (
                            <img 
                                src={`${baseUrl}${motorcycle.image_url}`} 
                                alt={motorcycle.name} 
                            />
                        )}
                        <h2>{motorcycle.name}</h2>
                        <p>{motorcycle.brand}</p>
                        <p>RM {motorcycle.price}</p>
                        <Link to={`/motorcycles/${motorcycle.id}`}>View Details</Link>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    );
};

export default Shop;
