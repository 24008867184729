import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Shop from './pages/Shop';
import ContactUs from './pages/ContactUs';
import AdminLogin from './pages/AdminLogin';
import AdminDashboard from './pages/AdminDashboard';
import MotorcycleDetail from './pages/MotorcycleDetail';
import ProtectedRoute from './components/ProtectedRoute';
import ClearToken from './components/ClearToken';


function App() {
    return (
        <Router>
            <ClearToken />
            <div className="App">
                <Routes>
                    <Route path="/home" element={<Home />} />
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/shop" element={<Shop />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/admin" element={<AdminLogin />} />
                    <Route path="/admin/dashboard" element={
                    <ProtectedRoute>
                        <AdminDashboard />
                    </ProtectedRoute>
                    } />
                    <Route path="/motorcycles/:id" element={<MotorcycleDetail />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
