import React, { useEffect } from 'react';
import '../css/Header.css';
import '../css/Home.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import Header from './Header';
import Footer from './Footer';
import logo from '../components/PUPhoto.jpeg';
import yamahaLogo from '../components/brands/yamaha.svg';
import hondaLogo from '../components/brands/honda.svg';
import suzukiLogo from '../components/brands/suzuki.svg';
import modenasLogo from '../components/brands/modenas.svg';
import symLogo from '../components/brands/sym.svg';
import benelliLogo from '../components/brands/benelli.png';
import keewayLogo from '../components/brands/keeway.png';
import wmotoLogo from '../components/brands/wmoto.png';
import brixtonLogo from '../components/brands/brixton.png';
import moriniLogo from '../components/brands/morini.png';
import qjmotorLogo from '../components/brands/qjmotor.png';
import cfmotoLogo from '../components/brands/cfmoto.svg';

const Home = () => {
  useEffect(() => {
    document.title = 'Home | P&U Cycle';
  }, []);

  return (
    <div>
      <Header />
      <div className="heading-container">
        <h1><i className="fa fa-home"></i> Home</h1>
      </div>
      <div className="carousel-container">
        <Carousel 
          showThumbs={false} 
          autoPlay 
          infiniteLoop 
          interval={3000} 
          showStatus={false}
          showIndicators={true}
          dynamicHeight={true}
          stopOnHover={true}
        >
          <div>
            <img src={logo} alt="First slide" />
            <p className="legend">Caption for first image</p>
          </div>
          <div>
          <img src={logo} alt="First slide" />
          <p className="legend">Caption for second image</p>
          </div>
          <div>
          <img src={logo} alt="First slide" />
          <p className="legend">Caption for third image</p>
          </div>
        </Carousel>
      </div>

      <h1 className="section-title"># Our Brands</h1>
      <div className="wrapper">
        <div className="icon">
          <a href="/shop?brand=yamaha" target="_self">
            <img src={yamahaLogo} alt="Yamaha" />
          </a>
        </div>
        <div className="icon">
            <a href="/shop?brand=honda" target="_self">
                <img src={hondaLogo} alt="Honda" />
            </a>
        </div>
        <div className="icon">
            <a href="/shop?brand=suzuki" target="_self">
                <img src={suzukiLogo} alt="Suzuki" />
            </a>
        </div>
        <div className="icon">
            <a href="/shop?brand=modenas" target="_self">
                <img src={modenasLogo} alt="Modenas" />
            </a>
        </div>
        <div className="icon">
            <a href="/shop?brand=sym" target="_self">
                <img src={symLogo} alt="SYM" />
            </a>
        </div>
        <div className="icon">
            <a href="/shop?brand=benelli" target="_self">
                <img src={benelliLogo} alt="Benelli" />
            </a>
        </div>
        <div className="icon">
          <a href="/shop?brand=keeway" target="_self">
          <img src={keewayLogo} alt="Keeway" />
          </a>
        </div>
        <div className="icon">
          <a href="/shop?brand=wmoto" target="_self">
            <img src={wmotoLogo} alt="WMoto" />
          </a>
        </div>
        <div className="icon">
          <a href="/shop?brand=brixton" target="_self">
            <img src={brixtonLogo} alt="Brixton" />
          </a>
        </div>
        <div className="icon">
          <a href="/shop?brand=morini" target="_self">
            <img src={moriniLogo} alt="Morini" />
          </a>
        </div>
        <div className="icon">
          <a href="/shop?brand=qjmotor" target="_self">
            <img src={qjmotorLogo} alt="QJMotor" />
          </a>
        </div>
        <div className="icon">
          <a href="/shop?brand=cfmoto" target="_self">
            <img src={cfmotoLogo} alt="CFMoto" />
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
