import { useEffect } from 'react';

const ClearToken = () => {
    useEffect(() => {
        localStorage.removeItem('token');
        console.log("ClearToken executed");
    }, []);

    return null;
};

export default ClearToken;
