import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import logo from '../components/PUPhoto.jpeg';
import '../css/ContactUs.css';

const branches = [
    {
        name: "P&U Cycle (Sg Buloh) (HQ)",
        address: "LOT 19753, Jln Kuala Selangor, Bandar Baru Sungai Buloh, 47000 Shah Alam, Selangor, Malaysia",
        phone: "+60 17-369 3663",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63737.0524041194!2d101.48729244863284!3d3.2080876000000225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc45710b209add%3A0x7565f92587e6e96c!2sP%20%26%20U%20Cycle%20(M)%20Sdn.%20Bhd.!5e0!3m2!1sen!2suk!4v1722597513838!5m2!1sen!2suk",
        social: {
            facebook: "https://www.facebook.com/pucyclesb/",
            instagram: "https://www.instagram.com/pucyclehq/",
            tiktok: "https://www.tiktok.com/@pucyclehq",
            whatsapp: "https://wa.me/60173693663"
        },
        hours: "Mon-Sat 9:30 - 19:00"
    },
    {
        name: "Petaling Utama Motor (PJ)",
        address: "No. 7 & 9, Jalan Petaling Utama1, Taman Petaling Utama, 46000 Petaling Jaya, Selangor, Malaysia",
        phone: "+60 17-335 3463",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.049615044102!2d101.64383461175427!3d3.0814319968812884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ba4e56ef7bd%3A0xd6f1571ca20da1a5!2sPetaling%20Utama%20Motor!5e0!3m2!1sen!2suk!4v1722346282737!5m2!1sen!2suk",
        social: {
            facebook: "https://www.facebook.com/pumotormalaysia",
            instagram: "https://www.instagram.com/pummotor",
            tiktok: "https://www.tiktok.com/@pummotor",
            whatsapp: "https://wa.me/60173353463"
        },
        hours: "Mon-Fri 9:00 - 18:00, Sat 9:00 - 17:00"
    },
    {
        name: "Petaling Utama Motor (Pudu)",
        address: "Wisma Pudu Emas, Lot 4A & 4B, Jalan Sungai Besi, Taman Johor Jaya, Johor, 81100 Johor Bahru, Malaysia",
        phone: "+60 3-9223 0018",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.854134404718!2d101.71063087477644!3d3.1332191532507796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc36222563366b%3A0xa66f79ee438dd4e2!2sPetaling%20Utama%20Motor%20(Pudu)%20Sdn.%20Bhd.!5e0!3m2!1sen!2suk!4v1722598503547!5m2!1sen!2suk",
        social: {
            facebook: "https://www.facebook.com/petalingutamapudu",
            instagram: "https://www.instagram.com/petalingutamamotorpudu/",
            tiktok: "https://www.tiktok.com/@petalingutamamotor",
            whatsapp: "https://wa.me/60173353463" // temporary steven number
        },
        hours: "Mon-Fri 8:30 - 17:30, Sat 8:30 - 13:00"
    },
    {
        name: "Petaling Utama Motor (Klang)",
        address: "99, Jalan Kapar, Kawasan 18, 41400 Klang, Selangor, Malaysia",
        phone: "+60 3-3345 9671",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.1516506401467!2d101.44383387477649!3d3.054052053730285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc53667ed6a617%3A0x5be03890e4c55ba3!2sPetaling%20Utama%20Motor%20(Klang)%20Sdn%20Bhd!5e0!3m2!1sen!2suk!4v1722599163690!5m2!1sen!2suk",
        social: {
            facebook: "https://www.facebook.com/PetalingUtamaMotors",
            instagram: "https://www.instagram.com/petalingutamamotor/",
            tiktok: "https://www.tiktok.com/@petalingmotorklang",
            whatsapp: "https://wa.me/60173353463" // temporary steven number
        },
        hours: "Mon-Fri 9:00 - 18:00, Sat 9:00 - 14:00"
    },
    {
        name: "P&U Motor (Keramat)",
        address: "No.2G, Jalan Jelatek 1, Jelatek Business Center, Kuala Lumpur, Malaysia",
        phone: "+60 3-4260 2060",
        mapUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.259615044102!2d101.65383461175427!3d3.1914319968812884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ba4e56ef7bd%3A0xd6f1571ca20da1a5!2sP%26U%20Motor%20Sdn%20Bhd%20(Keramat)!5e0!3m2!1sen!2suk!4v1722346282737!5m2!1sen!2suk",
        social: {
            facebook: "https://www.facebook.com/PUMOTORSB",
            instagram: "https://www.instagram.com/pu_motor_keramat/",
            tiktok: "https://www.tiktok.com/@pummotor", // temporary steven tiktok
            whatsapp: "https://wa.me/60173353463" // temporary steven number
        },
        hours: "Mon-Fri 8:30 - 17:30, Sat 8:30 - 12:30"
    }
];

const ContactUs = () => {
    useEffect(() => {
        document.title = 'Contact Us | P&U Cycle';
    }, []);

    return (
        <div>
            <Header />
            <div className="heading-container">
                <h1><i className="fas fa-map-marker-alt"></i> Our Branches</h1>
            </div>
            {branches.map((branch, index) => (
                <div className="contact-us-page" key={index}>
                    <div className="image-container">
                        <img src={logo} alt="PUM Store" className="store-image" />
                    </div>
                    <div className="info-map-container">
                        <div className="contactus-contact-info">
                            <h2 style={{ whiteSpace: 'pre-line' }}>{branch.name}</h2>
                            <ul>
                                <li><i className="fas fa-map-marker-alt"></i> {branch.address}</li>
                                <li><i className="fas fa-phone"></i> {branch.phone}</li>
                                <li><i className="fas fa-business-time"></i> {branch.hours}</li>
                            </ul>
                            <div className="contactus-social">
                                <a href={branch.social.facebook} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a>
                                <a href={branch.social.instagram} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a href={branch.social.tiktok} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-tiktok"></i>
                                </a>
                                <a href={branch.social.whatsapp} target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-whatsapp"></i>
                                </a>
                            </div>
                        </div>
                        <div className="map-container">
                            <iframe
                                src={branch.mapUrl}
                                style={{ border: 0, width: '100%', height: '100%' }}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                            ></iframe>
                        </div>
                    </div>
                </div>
            ))}
            <Footer />
        </div>
    );
};

export default ContactUs;
