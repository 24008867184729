// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.heading-container {
  text-align: center;
  width: 100%;
  padding: 1rem 0; 
}

.heading-container h1 {
  font-size: 2.5rem; 
  color: #333;
  position: relative;
  z-index: 2;
  margin: 0;
  padding: 0 1rem; 
}

.heading-container i {
  font-size: 2.5rem;
  color: #333;
  margin-right: 0.625rem; 
}

@media (max-width: 48rem) { 
  .heading-container h1, .heading-container i {
    font-size: 2rem; 
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE;aACW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  font-size: 1rem; \n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.heading-container {\n  text-align: center;\n  width: 100%;\n  padding: 1rem 0; \n}\n\n.heading-container h1 {\n  font-size: 2.5rem; \n  color: #333;\n  position: relative;\n  z-index: 2;\n  margin: 0;\n  padding: 0 1rem; \n}\n\n.heading-container i {\n  font-size: 2.5rem;\n  color: #333;\n  margin-right: 0.625rem; \n}\n\n@media (max-width: 48rem) { \n  .heading-container h1, .heading-container i {\n    font-size: 2rem; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
