import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../components/PULogo.png';
import { useNavigate } from 'react-router-dom';
import '../css/Header.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 

const Header = () => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleLoginRedirect = () => {
        navigate('/admin');
    };

    const toggleNav = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className="header">
          <div className="header-top">
            <div className="contact-info">
              <a href="mailto:pummotor@gmail.com" target="_blank" rel="noopener noreferrer">
                <i className="fa-solid fa-envelope"></i>
              </a>
              <a href="tel:+60173353463" target="_blank" rel="noopener noreferrer">
                <i className="fa-solid fa-phone"></i>
              </a>
              <a href="https://www.facebook.com/pumotormalaysia" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-facebook"></i>              
              </a>
              <a href="https://www.instagram.com/pummotor" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href="https://www.tiktok.com/@pummotor" target="_blank" rel="noopener noreferrer">
                <i className="fa-brands fa-tiktok"></i>
              </a>
              <div>
                <button className='login-button' onClick={handleLoginRedirect}>Login</button>
              </div>        
            </div>
          </div>
          <div className="header-main">
            <Link to="/home">
              <img src={logo} alt="PUCYCLE" className="logo" style={{ width: '100px', height: 'auto' }} />
            </Link>

            <div className={`nav ${menuOpen ? 'open' : ''}`}>
              <ul>
                <li><a href="/home"><b>Home</b></a></li>
                <li><a href="/about"><b>About Us</b></a></li>
                <li><a href="/shop"><b>Shop</b></a></li>
                <li><a href="/contact"><b>Contact Us</b></a></li>
              </ul>
            </div>

            <a href="https://web.whatsapp.com/send?phone=60173353463&text=Hello%21%20%F0%9F%8F%8D%EF%B8%8F%20I'm%20interested%20in%20learning%20more%20about%20your%20motorcycles%20and%20services.%20Can%20you%20please%20provide%20me%20with%20more%20details%3F%20Thank%20you!%20%F0%9F%98%8A%F0%9F%98%8A" target="_blank" rel="noopener noreferrer">
              <button className="contact-button">
                <i className="fa-brands fa-whatsapp" style={{ fontSize: '18px' }}></i>
                WhatsApp Us
              </button>
            </a>
            
            <div className="burger-menu" onClick={toggleNav}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>

          </div>
        </header>
    );
};

export default Header;
