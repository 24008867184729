import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../css/AboutUs.css';
import yamahaLogo from '../components/brands/yamaha.svg';
import hondaLogo from '../components/brands/honda.svg';
import suzukiLogo from '../components/brands/suzuki.svg';
import modenasLogo from '../components/brands/modenas.svg';
import symLogo from '../components/brands/sym.svg';
import benelliLogo from '../components/brands/benelli.png';
import keewayLogo from '../components/brands/keeway.png';
import wmotoLogo from '../components/brands/wmoto.png';
import brixtonLogo from '../components/brands/brixton.png';
import moriniLogo from '../components/brands/morini.png';
import qjmotorLogo from '../components/brands/qjmotor.png';
import cfmotoLogo from '../components/brands/cfmoto.svg';
import logo from '../components/PUPhoto.jpeg';

const AboutUs = () => {
    useEffect(() => {
        document.title = 'About Us | P&U Cycle';
        
        const leftTrack = document.querySelector(".aboutus-carousel-track.left-scroll");
        
        const cloneImages = (track) => {
            const images = Array.from(track.children);
            images.forEach(image => {
                const clone = image.cloneNode(true);
                track.appendChild(clone);
            });
        };

        cloneImages(leftTrack);
    }, []);

    return (
        <div className="about-us-container">
            <Header />
            <main className="about-us-content">
                <section className="about-us-text">
                    <div className="about-container">
                        <div className="heading-container">
                            <h1><i className="fa fa-info-circle"></i> Our Story</h1>
                        </div>
                        <div className="aboutus-image-container">
                            <img src={logo} alt="P & U Cycle" />
                        </div>
                        <div className="full-aboutus-text-container">
                            <div className="aboutus-text-container">
                                <h2>Welcome to<br/>P&U CYCLE</h2>
                                <p>Founded in 2005, P&U Cycle (M) Sdn. Bhd. has grown to become a prominent name in the Malaysian motorcycle industry. Our mission is to provide top-quality motorcycles and exceptional customer service to motorcycle enthusiasts across the country.
                                </p>
                            </div>
                            <div className="aboutus-text-container">
                                <h2>Our Services </h2>
                                <p><strong>New and Used Motorcycles:</strong> A comprehensive range of new and pre-owned motorcycles to suit all preferences and budgets.<br/>
                                <strong>Spare Parts and Accessories:</strong> An extensive inventory ensuring you find the right parts and accessories for your bike.<br/>
                                <strong>Expert Maintenance and Repairs:</strong> Skilled technicians providing top-notch maintenance and repair services to keep your motorcycle in prime condition.
                                </p>
                            </div>
                            <div className="aboutus-text-container">
                                <h2>Our Commitment </h2>
                                <p>At P&U Cycle, customer satisfaction is our top priority. We strive to create a seamless and enjoyable experience for every customer, ensuring they ride away with a smile.
                                </p>
                            </div>
                        </div>

                        <div className="aboutus-carousel-container">
                        <h2>Our Partners</h2>
                        <div className="aboutus-carousel">
                            <div className="aboutus-carousel-track left-scroll">
                                <img src={yamahaLogo} alt="Yamaha" />
                                <img src={hondaLogo} alt="Honda" />
                                <img src={suzukiLogo} alt="Suzuki" />
                                <img src={modenasLogo} alt="Modenas" />
                                <img src={symLogo} alt="SYM" />
                                <img src={benelliLogo} alt="Benelli" />
                                <img src={keewayLogo} alt="Keeway" />
                                <img src={brixtonLogo} alt="Brixton" />
                                <img src={wmotoLogo} alt="WMoto" /> 
                                <img src={moriniLogo} alt="Morini" />
                                <img src={qjmotorLogo} alt="QJ Motor" />
                                <img src={cfmotoLogo} alt="CFMoto" />
                            </div>
                        </div>
                    </div>

                    </div>


                </section>
            </main>
            <Footer />
        </div>
    );
};

export default AboutUs;