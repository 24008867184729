import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import '../css/AdminLogin.css';

const AdminLogin = () => {
    useEffect(() => {
        document.title = 'Login | P&U Cycle';
      }, []);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    
    useEffect(() => {
        if (localStorage.getItem('token')) {
            navigate('/admin/dashboard');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Sending Data:', { username, password });
        try {
            const res = await axios.post('https://petalingutamamotor.com/api/admin/login', { username, password });
            localStorage.setItem('token', res.data.token);
            navigate('/admin/dashboard');
        } catch (err) {
            setError(err.response ? err.response.data.msg : 'Login failed');
            console.error(err);
        }
    };

    return (
        <div>
            <Header />
            <div className="login-wrapper">
                <div class="login-container">
                    <h1>Admin Login</h1>
                    {error && <p class="error">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Username</label>
                            <input 
                                type="text" 
                                value={username} 
                                onChange={(e) => setUsername(e.target.value)} 
                                required 
                            />
                        </div>
                        <div>
                            <label>Password</label>
                            <input 
                                type="password" 
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} 
                                required 
                            />
                        </div>
                        <button type="submit">LOG IN</button>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AdminLogin;
