import React from 'react';
import '../css/Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import { Link } from 'react-router-dom';
import logo from '../components/PULogo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">

        <div className="footer-section about">
          <div className="logo-container">
              <Link to="/home">
                <img src={logo} alt="PUCYCLE" className="logo" />
              </Link>
          </div>
          <p>
            Explore our showroom and discover a wide range of top-quality motorcycles from leading brands, we have something to suit every style and preference. 
          </p>
        </div>

        <div className="footer-section contact-social">
          <div className="contact">
            <h2>Contact Us</h2>
            <ul>
              <li><i className="fas fa-map-marker-alt"></i> No. 7 & 9, Jalan Petaling Utama1, Taman Petaling Utama, 46000 Petaling Jaya, Selangor</li>
              <li><i className="fas fa-phone"></i> +60 3-7783 2060</li>
              <li><i className="fas fa-envelope"></i> motorpum@gmail.com</li>
            </ul>
          </div>
          <div className="social">
            <a href="https://www.facebook.com/pumotormalaysia" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/pummotor" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@pummotor" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
            <a href="https://wa.me/60173353463" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>

        <div className="footer-section map">
          <h2>Our Location</h2>
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.049615044102!2d101.64383461175427!3d3.0814319968812884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ba4e56ef7bd%3A0xd6f1571ca20da1a5!2sPetaling%20Utama%20Motor!5e0!3m2!1sen!2suk!4v1722346282737!5m2!1sen!2suk" 
            style={{ border: 0 }} 
            allowFullScreen="" 
            aria-hidden="false" 
            tabIndex="0">
          </iframe>
        </div>

      </div>

      <div className="footer-bottom">
        <p>Copyright &copy; 2024 P&U Cycle. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
